import React, { useEffect, useState, useRef } from "react"
import { toc, firstLevel, otherLevel, activeHeader } from "./toc.module.scss"

function getIds(items) {
  return items.reduce((acc, item) => {
    if (item.url) {
      // url has a # as first character, remove it to get the raw CSS-id
      acc.push(item.url.slice(1))
    }
    if (item.items) {
      acc.push(...getIds(item.items))
    }
    return acc
  }, [])
}

function useActiveId(itemIds) {
  const [activeId, setActiveId] = useState(`test`)
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setActiveId(entry.target.id)
          }
        })
      },
      { rootMargin: `0% 0% -80% 0%` }
    )
    itemIds.forEach(id => {
      const element = document.getElementById(id)
      if (element != null) observer.observe(element)
    })
    return () => {
      itemIds.forEach(id => {
        const element = document.getElementById(id)
        if (element != null) observer.unobserve(element)
      })
    }
  }, [itemIds])
  return activeId
}

function renderItems(items, activeId, levelClassName) {
  return (
    <ol className={levelClassName}>
      {items.map(item => {
        return (
          <li key={item.url}>
            <a
              href={item.url}
              className={activeId === item.url.slice(1) ? activeHeader : ""}
            >
              {item.title}
            </a>
            {item.items && renderItems(item.items, activeId, otherLevel)}
          </li>
        )
      })}
    </ol>
  )
}

function TableOfContents(props) {
  const idList = getIds(props.items)
  const activeId = useActiveId(idList)

  return (
    <details open className={toc}>
      <summary>Table of Contents</summary>
      <nav>{renderItems(props.items, activeId, firstLevel)}</nav>
    </details>
  )
}

export default TableOfContents
