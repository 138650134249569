import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import * as BreadcrumbStyles from "./breadcrumb.module.scss"

// write a gatsby component to implement breadcrumb
const Breadcrumb = ({ group, title }) => {
  const data = useStaticQuery(graphql`
    query {
      verilog: allMdx(
        filter: { fields: { group: { eq: "verilog" } } }
        sort: { fields: [frontmatter___sl_no], order: ASC }
      ) {
        edges {
          node {
            slug
            frontmatter {
              title
              sl_no
            }
            id
          }
        }
      }

      system_verilog: allMdx(
        filter: { fields: { group: { eq: "sv" } } }
        sort: { fields: [frontmatter___sl_no], order: ASC }
      ) {
        edges {
          node {
            slug
            frontmatter {
              title
              sl_no
            }
            id
          }
        }
      }

      uvm: allMdx(
        filter: { fields: { group: { eq: "uvm" } } }
        sort: { fields: [frontmatter___sl_no], order: ASC }
      ) {
        edges {
          node {
            slug
            frontmatter {
              title
              sl_no
            }
            id
          }
        }
      }
    }
  `)

  const [showDialog, setShowDialog] = useState(false)

  // stores the list of pages and their slug according to the group
  var list = null
  //   var slug = null
  if (group === "verilog") list = data.verilog.edges
  else if (group === "sv") list = data.system_verilog.edges
  else if (group === "uvm") list = data.uvm.edges

  //   forEach(list, item => {
  //     if (item.node.frontmatter.title === title) {
  //       slug = item.node.slug
  //     }
  //   })

  // create a list of crumbs
  const crumbs = [
    { label: "Home", link: "/" },
    { label: "Content", link: "/content/" },
    { label: group.toUpperCase() },
    //{ label: title.toUpperCase(), link: `/content/${slug}/` },
  ]

  return (
    <div aria-label="breadcrumb" className={BreadcrumbStyles.breadcrumb}>
      <ol>
        {crumbs.map((crumb, index) => (
          <li key={index}>
            {crumb.link ? (
              <Link to={crumb.link}>{crumb.label}</Link>
            ) : (
              <div
                aria-label="button"
                onClick={() => {
                  setShowDialog(!showDialog)
                }}
              >
                {crumb.label}
              </div>
            )}
          </li>
        ))}
      </ol>
      <div
        className={`${BreadcrumbStyles.breadcrumbContentBox} ${
          showDialog ? BreadcrumbStyles.active : ""
        }`}
      >
        <div
          aria-label="button"
          onClick={() => {
            setShowDialog(!showDialog)
          }}
        >
          X
        </div>
        <ul>
          {list.map((item, index) => (
            <li key={index}>
              <Link to={`/content/${item.node.slug}/`}>
                {item.node.frontmatter.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Breadcrumb
