import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import * as contentTableStyles from "./content-table.module.scss"

// group is passed from the page and the component fetches all the page of
// that group and makes a table.
// As only one static query is allowed per component, thus for all the groups
// query is done and then according to the ~group~ passed from page, the table
// is rendered
const ContentTable = ({ group }) => {
  const data = useStaticQuery(graphql`
    query {
      verilog: allMdx(
        filter: { fields: { group: { eq: "verilog" } } }
        sort: { fields: [frontmatter___sl_no], order: ASC }
      ) {
        edges {
          node {
            slug
            frontmatter {
              title
              sl_no
            }
            id
          }
        }
      }

      system_verilog: allMdx(
        filter: { fields: { group: { eq: "sv" } } }
        sort: { fields: [frontmatter___sl_no], order: ASC }
      ) {
        edges {
          node {
            slug
            frontmatter {
              title
              sl_no
            }
            id
          }
        }
      }

      uvm: allMdx(
        filter: { fields: { group: { eq: "uvm" } } }
        sort: { fields: [frontmatter___sl_no], order: ASC }
      ) {
        edges {
          node {
            slug
            frontmatter {
              title
              sl_no
            }
            id
          }
        }
      }
    }
  `)

  // stores the list of pages and their slug according to the group
  var list = null
  if (group === "verilog") list = data.verilog.edges
  else if (group === "sv") list = data.system_verilog.edges
  else if (group === "uvm") list = data.uvm.edges

  //   console.log(data)
  if (group == null) {
    return <div></div>
  }

  // console.log(sdata)
  console.log(list)
  list.sort((a, b) => {
    return a.node.frontmatter.sl_no - b.node.frontmatter.sl_no
  })

  console.log(list)
  return (
    <div className={contentTableStyles.tableContainer}>
      <h3>Topics</h3>
      <div>
        <ol>
          {list.map(item => (
            <li key={item.id}>
              <Link
                to={`/content/${item.node.slug}/`}
                activeStyle={{
                  background: "rgba(59, 59, 59)",
                  color: "rgb(223, 244, 253)",
                }}
              >
                {item.node.frontmatter.title}
              </Link>
            </li>
          ))}
        </ol>
      </div>
    </div>
  )
}

export default ContentTable
