import PropTypes from "prop-types"

const structDataGen = (headline, desc, url, pDate, mDate) => {
  const strucData = `{
        "@context": "https://schema.org",
        "@type": "Article",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "https://www.theoctetinstitute.com/content/${url}/"
        },
        "headline": "${headline}",
        "description": "${desc}",
        "image": "",  
        "author": {
          "@type": "Organization",
          "name": "The Octet Institute"
        },  
        "publisher": {
          "@type": "Organization",
          "name": "The Octet Institute",
          "logo": {
            "@type": "ImageObject",
            "url": "https://www.theoctetinstitute.com/og-img.png"
          }
        },
        "datePublished": "${pDate}",
        "dateModified": "${mDate}"
      }`

  return strucData
}

export default structDataGen

structDataGen.propTypes = {
  headline: PropTypes.string,
  desc: PropTypes.string,
  url: PropTypes.string,
  pDate: PropTypes.string,
  mDate: PropTypes.string,
}

structDataGen.defaultProps = {
  headline: "The Octet Institute",
  desc: "",
  url: "",
  pDate: "",
  mDate: "",
}
