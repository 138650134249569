import React from "react"

import * as styles from "./share-actn-btn.module.scss"

const shareData = sharedData => {
  //   console.log(sharedData)
  try {
    navigator.share(sharedData)
    // console.log("Share successfull")
  } catch (e) {
    console.log("Error: ", e)
  }
}

const ShareActionBtn = ({ sharedData }) => {
  //   console.log(sharedData)
  return (
    <>
      <button
        className={styles.actnBtn}
        onClick={() => shareData(sharedData)}
        aria-label="share the page with friends"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#000000"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92-1.31-2.92-2.92-2.92z" />
        </svg>
      </button>
    </>
  )
}

export default ShareActionBtn
