import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import * as paginationStyles from "./pagination.module.scss"

const PageView = ({ children, prev, next, group }) => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      verilog: allMdx(
        sort: { fields: frontmatter___sl_no, order: ASC }
        filter: { fields: { group: { eq: "verilog" } } }
      ) {
        edges {
          node {
            slug
            frontmatter {
              title
              sl_no
            }
          }
        }
      }

      system_verilog: allMdx(
        sort: { fields: frontmatter___sl_no, order: ASC }
        filter: { fields: { group: { eq: "sv" } } }
      ) {
        edges {
          node {
            slug
            frontmatter {
              title
              sl_no
            }
          }
        }
      }

      uvm: allMdx(
        sort: { fields: frontmatter___sl_no, order: ASC }
        filter: { fields: { group: { eq: "uvm" } } }
      ) {
        edges {
          node {
            slug
            frontmatter {
              title
              sl_no
            }
          }
        }
      }
    }
  `)
  var prevLink = null
  var nextLink = null

  var verilog_list = data.verilog.edges
  verilog_list.sort((a, b) => {
    return a.node.frontmatter.sl_no - b.node.frontmatter.sl_no
  })

  var sv_list = data.system_verilog.edges
  sv_list.sort((a, b) => {
    return a.node.frontmatter.sl_no - b.node.frontmatter.sl_no
  })

  var uvm_list = data.uvm.edges
  uvm_list.sort((a, b) => {
    return a.node.frontmatter.sl_no - b.node.frontmatter.sl_no
  })

  if (group == null) return
  if (prev > -1) {
    switch (group) {
      case "verilog":
        prevLink = verilog_list[prev].node.slug
        // console.log(prevLink)
        break

      case "sv":
        prevLink = sv_list[prev].node.slug
        break

      case "uvm":
        prevLink = uvm_list[prev].node.slug
        break

      default:
        prevLink = null
    }
  }

  switch (group) {
    case "verilog":
      if (next < data.verilog.edges.length)
        nextLink = verilog_list[next].node.slug

      //   console.log(prevLink)
      break

    case "sv":
      if (next < data.system_verilog.edges.length)
        nextLink = sv_list[next].node.slug
      break

    case "uvm":
      if (next < data.uvm.edges.length) nextLink = uvm_list[next].node.slug
      break

    default:
      nextLink = null
  }

  //   console.log(data.verilog.edges[prev].node.slug)

  return (
    <div className={paginationStyles.page}>
      {children}
      <div className={paginationStyles.pageNavbar}>
        {prevLink == null ? (
          <div></div>
        ) : (
          <Link to={`/content/${prevLink}/`}>Prev</Link>
        )}
        {/* <Link to={prev}>Prev</Link> */}
        {nextLink == null ? (
          //   <div></div>
          <Link to={`/content/`}>Back to Contents</Link>
        ) : (
          <Link to={`/content/${nextLink}/`}>Next</Link>
        )}
      </div>
    </div>
  )
}

export default PageView
