import React from "react"
import LazyAd from "./lazy-ad"

const SideAd = ({ adSlot }) => (
  <div style={{ marginBottom: 20 /*, backgroundColor: "#ffeded"*/ }}>
    <LazyAd
      adStyle={{ display: "block", width: "160px", height: "600px" }}
      adClient="ca-pub-9143856790267788"
      adSlot={adSlot}
      adFormat="auto"
    />
  </div>
)

export default SideAd
