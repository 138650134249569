// src/components/LazyAd.js
import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"

let isAdsenseScriptLoaded = false

const LazyAd = ({ adStyle, adClient, adSlot, adFormat, adLayout }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "200px", // Adjust as needed
  })

  useEffect(() => {
    if (inView && typeof window !== "undefined") {
      if (!isAdsenseScriptLoaded) {
        const script = document.createElement("script")
        script.src =
          "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        script.async = true
        script.onload = () => {
          isAdsenseScriptLoaded = true
          ;(window.adsbygoogle = window.adsbygoogle || []).push({})
          console.log("Lazy loaded ad from component")
        }
        document.body.appendChild(script)
      } else {
        ;(window.adsbygoogle = window.adsbygoogle || []).push({})
      }
    }
  }, [inView])

  return (
    <div
      ref={ref}
      style={{ /*backgroundColor: "#e0ecff",*/ position: "sticky", top: 80 }}
    >
      {inView && (
        <ins
          className="adsbygoogle"
          style={adStyle}
          data-ad-client={adClient}
          data-ad-slot={adSlot}
          data-ad-format={adFormat}
          data-ad-layout={adLayout}
          data-full-width-responsive="true"
        />
      )}
    </div>
  )
}

export default LazyAd
