import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "../components/layout"
import SEO from "../components/SEO"
import PageView from "../components/pagination"
import ContentTable from "../components/content-table"
import structDateGen from "../utils/struct-data-gen"
import ShareActionBtn from "../components/share-actn-btn"
import ShareButtons from "../components/share-buttons"
import TableOfContents from "../components/toc"
import Breadcrumb from "../components/breadcrumb"
import SideAd from "../components/side-ad"

import * as blogStyle from "./mdx-blog.module.scss"
import "./grvsc-override.scss"

import readerIcon from "../images/schedule_black_18dp.svg"
import dateIcon from "../images/today_black_18dp.svg"

export const query = graphql`
  query ($slug: String!) {
    mdx(slug: { eq: $slug }) {
      frontmatter {
        title
        description
        p_date(formatString: "YYYY-MM-DD")
        m_date(formatString: "YYYY-MM-DD")
        formattedDate: m_date(formatString: "DD MMM YYYY")
      }
      body
      tableOfContents(maxDepth: 4)
      timeToRead
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const table = props => {
  // TODO: Find a solution to render a content table
  // temperory solution for navigation until other solution is found
  return <ContentTable group={props.pageContext.group} />

  // use this solution for future use
  //   if (props.pageContext.sl_no === 0) {
  //     return <ContentTable group={props.pageContext.group} />
  //   } else {
  //     return
  //   }
}

const Blog = props => {
  //   console.log(props)

  //   const strucData = `{
  //     "@context": "https://schema.org",
  //     "@type": "Article",
  //     "mainEntityOfPage": {
  //       "@type": "WebPage",
  //       "@id": "https://www.theoctetinstitute.com/content/verilog/0-introduction/"
  //     },
  //     "headline": "Introduction to Verilog",
  //     "description": "In this article, the basics of Verilog is discussed.",
  //     "image": "",
  //     "author": {
  //       "@type": "Organization",
  //       "name": "The Octet Institute"
  //     },
  //     "publisher": {
  //       "@type": "Organization",
  //       "name": "The Octet Institute",
  //       "logo": {
  //         "@type": "ImageObject",
  //         "url": "https://www.theoctetinstitute.com/og-img.png"
  //       }
  //     },
  //     "datePublished": "2021-07-13",
  //     "dateModified": "2021-07-13"
  //   }`

  const structData = structDateGen(
    props.data.mdx.frontmatter.title,
    props.data.mdx.frontmatter.description,
    props.pageContext.slug,
    props.data.mdx.frontmatter.p_date,
    props.data.mdx.frontmatter.m_date
  )

  const sharedData = {
    title: props.data.mdx.frontmatter.title,
    text: props.data.mdx.frontmatter.description,
    url: `${props.data.site.siteMetadata.siteUrl}/content/${props.pageContext.slug}/`,
  }

  //   console.log("Struct data: ", structData)
  return (
    <Layout>
      <SEO
        title={props.data.mdx.frontmatter.title}
        description={props.data.mdx.frontmatter.description}
        article={true}
        structData={structData}
      />

      {/* Article conatiner which will hold the components for article */}
      <div className={blogStyle.articleContainer}>
        {/* Side conatiner which will hold TOC and ads */}
        <aside
          className={`${blogStyle.asideContainer} ${blogStyle.firstContainer}`}
        >
          <SideAd adSlot="6413594673" />
          <SideAd adSlot="5805593155" />
          <SideAd adSlot="9640233675" />
        </aside>
        <div>
          <Breadcrumb
            group={props.pageContext.group}
            title={props.data.mdx.frontmatter.title}
          />
          <PageView
            prev={props.pageContext.sl_no - 1}
            next={props.pageContext.sl_no + 1}
            group={props.pageContext.group}
          >
            {/* Main article */}
            <article className={blogStyle.blog}>
              <div className={blogStyle.header}>
                <h1>{props.data.mdx.frontmatter.title}</h1>
                <div className={blogStyle.metaInfo}>
                  <div>
                    <img src={dateIcon} alt="" height="18px" />
                    <h5>{props.data.mdx.frontmatter.formattedDate}</h5>
                  </div>

                  <div>
                    <img src={readerIcon} alt="" height="18px" />
                    <h5>{props.data.mdx.timeToRead + 2 + " mins"}</h5>
                  </div>
                </div>
              </div>
              <MDXRenderer>{props.data.mdx.body}</MDXRenderer>

              <aside>
                <div>Share with others</div>
                {/* <div class="s9-widget-wrapper"></div> */}
                <ShareButtons url={sharedData.url} title={sharedData.title} />
              </aside>
            </article>
          </PageView>

          {/* Prints the content table */}
          {table(props)}
          <ShareActionBtn sharedData={sharedData} />
        </div>

        <aside
          className={`${blogStyle.asideContainer} ${blogStyle.secondContainer}`}
        >
          {props.data.mdx?.tableOfContents?.items && (
            <TableOfContents items={props.data.mdx.tableOfContents.items} />
          )}
          {/* <div className={blogStyle.adContainer}>test</div> */}
        </aside>
      </div>
    </Layout>
  )
}

export default Blog
